import React, { useEffect, useState } from "react"
import RecovaHeaderLayout from "pages/Dashboard/Recova/layout/RecovaHeaderLayout"
import clsx from "clsx";
import Table from "components/Table";
import { calculateDuration, copyText, currencyFormatter, dateFormatter, generatePDF, handleRequestErrors, timeFormatter } from "utils/helpers";
import Status from "components/shared/Status";
import OutsideClickHandler from 'react-outside-click-handler';

import arrowUp from "images/svg/overview/arrow-up.svg";
import arrowDown from "images/svg/overview/arrow-down.svg";
import chartUp from "images/svg/overview/chart_up.svg";
import chartDown from "images/svg/overview/chart_down.svg";
import smiley from "images/svg/recova/empty.svg";
import dropdown from "images/svg/table_dropdown.svg";
import tableIcon from "images/svg/loan-performance.svg";
import { RecovaService } from "services/recova.service";
import { toast } from "react-toastify";
import Filter from "components/Table/Filter";
import copy from "images/svg/recova/copy.svg"
import DownloadModal from "./components/DownloadModal";
import PDFView from "components/PDFView";
import dayjs from "dayjs";
import Button from "components/shared/Button";


function Collections () {
    const [path, setPath] = useState("auto");
    const [viewMore, setViewMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [filterTerm, setFilterTerm] = useState("all");
    const [stats, setStats] = useState(null)
    const [showDownload, setShowDownload] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false)
    const [PDFData, setPDFData] = useState(null)

    useEffect(()=>{
        setIsLoading(true)
        RecovaService.getCollections({
            type: path, 
            search: searchTerm, 
            status: filterTerm==="all"?"":filterTerm
        })
            .then(response=> response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status){
                    setData(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setIsLoading(false)
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, searchTerm, filterTerm])

    useEffect(()=>{
        getCollectionStats()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path])

    const getCollectionStats = () => {
        RecovaService.getCollectionStats(path)
            .then(response=> response.data)
            .then(res => {
                if(res.status===true){
                    setStats(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }

    const handleDownload = (startDate, endDate) => {
        if(!isDownloading){
            setIsDownloading(true)
            RecovaService.getCollections({
                type: path, 
                status: "success",
                from: dateFormatter(startDate, "yyyy-mm-dd") , 
                to: dateFormatter(endDate, "yyyy-mm-dd"),
            })
                .then(response=> response.data)
                .then(res => {
                    setIsDownloading(false)
                    if(res.status && res?.data.length>0){
                        setPDFData({
                            ...PDFData,
                            dates: {
                                startDate, endDate
                            },
                            duration: calculateDuration(startDate, endDate),
                            data: res?.data
                        })
                        generatePDF(
                            "collection-settlement-breakdown", 
                            "collection-breakdown",
                            ()=>{
                                setIsDownloading(false)
                                setShowDownload(false)
                            }
                        )
                    } else {
                        toast.info("There is no data to the selected time period!")
                    }
                })
                .catch((err) => {
                    setIsDownloading(false)
                });
        }
    }
    
    return(
        <RecovaHeaderLayout>
            <div>
                <h1 className="text-[25px] font-bold">Collections</h1>
                <div className="flex items-center text-[15px] mt-3">
                    <div className={clsx(
                            "flex items-center mr-[20px] px-2 py-1 cursor-pointer",
                            path==="auto"&&"bg-cc-primary rounded-2xl text-white",
                        )}
                        onClick={()=>setPath("auto")}
                    >
                        <p>Automated Collections</p>
                    </div>
                    <div className={clsx(
                            "flex items-center mr-[20px] px-2 py-1 cursor-pointer",
                            path==="manual"&&"bg-cc-primary rounded-2xl text-white",
                        )}
                        onClick={()=>setPath("manual")}
                    >
                        <p>Manual Collections</p>
                    </div>
                </div>
                <div>
                    <div className="grid grid-cols-3 gap-[30px] my-[30px]">
                        <div className="shadow-md border-[1px] border-solid border-cc-grey-1 rounded-[8px] p-2.5">
                            <div className="flex justify-between items-center">
                                <p className="font-semibold">Completed Collections</p>
                                <p className="font-semibold text-[30px]">{stats?.completedCount||0}</p>
                            </div>
                            <div className="flex justify-between items-end">
                                <h4 className="text-[24px] my-[10px]">{currencyFormatter(stats?.completedAmount||0)}</h4>
                                <div>
                                    <img 
                                        src={stats?.completedDifference>0 ? chartUp:chartDown} 
                                        alt="" 
                                        className="ml-auto" 
                                    />
                                    <div className="text-cc-grey-4 flex text-xs">
                                        <img 
                                            src={stats?.completedDifference>0 ? arrowUp:arrowDown} 
                                            alt="" 
                                            className="w-3" 
                                        />
                                        <p>
                                            <span 
                                                className={clsx(
                                                    stats?.completedDifference>0 ? "text-cc-green-5":"text-cc-red-9"
                                                )}
                                            >
                                                {Math.abs(stats?.completedDifference)}%
                                            </span> {" "}
                                            vs last month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="shadow-md border-[1px] border-solid border-cc-grey-1 rounded-[8px] p-2.5">
                            <div className="flex justify-between items-center">
                                <p className="font-semibold">Failed Collections</p>
                                <p className="font-semibold text-[30px]">{stats?.failedCount||0}</p>
                            </div>
                            <div className="flex justify-between items-end">
                                <h4 className="text-[24px] my-[10px]">{currencyFormatter(stats?.failedAmount||0)}</h4>
                                <div>
                                    <img src={chartDown} alt="" className="ml-auto" />
                                    {/* <div className="text-cc-grey-4 flex text-xs">
                                        <img src={arrowDown} alt="" className="w-3" />
                                        red-9, green-5
                                        <p><span  className="text-cc-red-9">0%</span> vs last month</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="shadow-md border-[1px] border-solid border-cc-grey-1 rounded-[8px] p-2.5">
                            <div className="flex justify-between items-center">
                                <p className="font-semibold">Upcoming Collections</p>
                                <p className="font-semibold text-[30px]">{stats?.upcomingCount||0}</p>
                            </div>
                            <div className="flex justify-between items-end">
                                <h4 className="text-[24px] my-[10px]">{currencyFormatter(stats?.upcomingAmount||0)}</h4>
                                <div>
                                    <img src={chartUp} alt="" className="ml-auto" />
                                    {/* <div className="text-cc-grey-4 flex text-xs">
                                        <img src={arrowDown} alt="" className="w-3" />
                                        red-9, green-5
                                        <p><span  className="text-cc-red-9">0%</span> vs last month</p>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <Table
                        titleIcon={tableIcon}
                        tableTitle={"Collection Activities"}
                        data={data}
                        loading={isLoading}
                        hideSearch={false}
                        trClassName={"relative"}
                        onSearchChange={(e)=>setSearchTerm(e.target.value)}
                        tableClassName={clsx(
                            "min-w-[1100px]",
                            viewMore && "mb-[200px]"
                        )}
                        searchPlaceHolder={"Search by BVN and MandateRef"}
                        searchContainerClass="xl_min:min-w-[300px] min-w-[300px] sm:!w-full"
                        searchInputClass="!text-sm !text-cc-grey-4"
                        searchIconFirst={true}
                        slotBeforeSearch={
                            filterTerm==="success" &&
                            <Button
                                isValid={true}
                                type="button"
                                text="Download Report"
                                className="!font-bold text-base !text-black px-[10px] py-[6px] border border-[#D0D5DD] !bg-[#1018280D]"
                                icon={
                                    <svg className="scale-[0.7]" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5556 17.25H16.5667M17 13.5H17.6667C18.7021 13.5 19.2198 13.5 19.6282 13.6902C20.1727 13.944 20.6053 14.4308 20.8309 15.0433C21 15.5028 21 16.0851 21 17.25C21 18.4149 21 18.9972 20.8309 19.4567C20.6053 20.0692 20.1727 20.556 19.6282 20.8097C19.2198 21 18.7021 21 17.6667 21H4.33333C3.29791 21 2.7802 21 2.37181 20.8097C1.82731 20.556 1.3947 20.0692 1.16916 19.4567C1 18.9972 1 18.4149 1 17.25C1 16.0851 1 15.5028 1.16916 15.0433C1.3947 14.4308 1.82731 13.944 2.37181 13.6902C2.7802 13.5 3.29791 13.5 4.33333 13.5H5M11 14.75V1M11 14.75L7.66667 11M11 14.75L14.3333 11" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                }
                                iconPosition="left"
                                onClick={()=>setShowDownload(true)}
                            />
                        }
                        slot={
                            <Filter
                                filterValue={filterTerm}
                                onChange={(v)=>setFilterTerm(v)}
                                options={[
                                    { label: "Filter", value: "all", showOnList: false },
                                    { label: <span className="font-bold text-[#DE213C]">Failed</span>, value: "failed", showOnList: true },
                                    { label: <span className="font-bold text-cc-green-5">Success</span>, value: "success", showOnList: true },
                                    { label: <span className="font-bold text-cc-grey-8">Clear all Filters</span>, value: "all", showOnList: true },
                                ]}
                                hideCustom={true}
                                extraClass={"!text-sm"}
                                optionClass={"!text-sm !text-center"}
                                containerClass="!h-[40px] !rounded-[8px] pt-1"
                            />
                        }
                        rows={[
                            {
                                header:'Product Name',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px]">
                                        {item?.mandateId?.productId?.name||"Not available"}
                                    </p>
                                ),
                            },
                            {
                                header:'Account Name',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px]">
                                        {item?.accountName||"Not available"}
                                    </p>
                                ),
                            },
                            {
                                header:'Amount Triggered',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                        {currencyFormatter(item.totalAmount||item.loanAmount||0)}
                                    </p>
                                ),
                            },
                            {
                                header:'Amount Collected',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                        {currencyFormatter(item.amountCollected||0)}
                                    </p>
                                ),
                            },
                            {
                                header:'Date of Collection',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                        {dateFormatter(item.collectedOn, "dd-mm-yyyy")} {timeFormatter(item.collectedOn)}
                                    </p>
                                ),
                            },
                            {
                                header:'Mode of Collection',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                        {item?.collectionType||"Not available"}
                                    </p>
                                ),
                            },
                            {
                                header:'Status',
                                view: (item) => (
                                    <p className="py-[10px] pl-[10px] pr-[35px]">
                                            <Status type={item?.status==="success" ?"success":"fail"} text={item.status}/>
                                    </p>
                                ),
                            },
                            {
                                header:'',
                                view: (item) => (
                                    <TableDropdown item={item} expand={(val)=>setViewMore(val)} />
                                ),
                            },
                        ]}
                        tableEmpty={
                            <div className="flex items-center my-[120px] mx-[20px] justify-center">
                                <p className="text-cc-grey-4 mr-3">No collection activities!</p>
                                <img src={smiley} alt="" className="w-[40px]" />
                            </div>
                        }
                    />
                </div>
            </div>
            <DownloadModal
                show={showDownload}
                onClose={()=>setShowDownload(false)}
                onDownloadClick={handleDownload}
                isDownloading={isDownloading}
            />
            <PDFFile data={PDFData} />
        </RecovaHeaderLayout>
        
    )
}

const PDFFile = ({data}) => {
    return (
        <PDFView id={"collection-settlement-breakdown"}>
            <div className="bg-cc-primary text-white py-10 bg-[url('./images/svg/sidebar/bg-image.svg')] min-h-[295mm]">
                <div>
                    <h3 className="text-[32px] font-bold text-center">Collections Breakdown</h3>
                    <p className="w-[310px] mx-auto block text-[18px] font-normal text-center">Summary of your collection details for reference purpose</p>
                </div>
                <div className="bg-white mt-10">
                    <div className="py-6 px-10">
                        <h4 className="text-cc-primary text-[20px] font-bold my-2.5">Collections Schedule </h4>
                        <div className="flex gap-x-6">
                            {
                                [
                                    {title: "Total Collections", value: data?.data?.length||0},
                                    {title: "Date Range", value: `${dayjs(data?.dates?.startDate).format("DD MMM")} - ${dayjs(data?.dates?.endDate).format("DD MMM, YYYY")}`},
                                    {title: "Duration", value: data?.duration},
                                ].map(item => (
                                    <div className="flex gap-x-2.5 text-[#1D2939] text-base font-medium">
                                        <p>{item.title}:</p>
                                        <p>{item.value}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="text-sm mx-auto block p-2.5 rounded bg-[#E5EEFF]">
                        <div className="text-white p-2.5 bg-cc-primary rounded font-bold grid grid-cols-5 text-center">
                            <p>Account Name</p>
                            <p>Bank Account</p>
                            <p>Amount Collected</p>
                            <p>Date of collections</p>
                            <p>Mode of collections</p>
                        </div>
                        {
                            data?.data?.map((item, idx)=>(
                                <div 
                                    key={item._id} 
                                    className={clsx(
                                        "grid grid-cols-5 text-[#1D2939] py-2.5 text-center",
                                        data?.data?.length!==idx+1 && "border-b-[1px] border-b-[#D0D5DD]"
                                    )}
                                >
                                    <p>{item?.accountName || "---"}</p>
                                    <p>{item?.accountNumber || "---"}</p>
                                    <p>{currencyFormatter(item?.amountCollected || 0)}</p>
                                    <p>{dayjs(item?.collectedOn).format("DD-MM-YYYY hh:mm A")}</p>
                                    <p className="capitalize">{item?.collectionType}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="flex items-center justify-center gap-x-1.5 mt-6">
                    <p className="text-xs">Powered by</p>
                    <svg width="91" height="21" viewBox="0 0 91 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.1307 19.3946L15.1596 14.1882C16.185 12.9318 16.7506 11.341 16.7572 9.69543C16.7751 5.79609 13.7032 2.61167 9.90644 2.5942C6.10973 2.57674 3.00102 5.73793 2.98308 9.63728C2.96514 13.5366 6.03711 16.721 9.83382 16.7385C11.385 16.748 12.8943 16.2155 14.1181 15.2269L19.1074 20.4478C19.2421 20.5888 19.4253 20.6685 19.6167 20.6694C19.8081 20.6704 19.992 20.5924 20.128 20.4528C20.264 20.3131 20.341 20.1232 20.342 19.9248C20.3429 19.7263 20.2678 19.5356 20.1332 19.3946L20.1307 19.3946ZM9.8408 15.2222C6.85425 15.2085 4.43208 12.7034 4.44616 9.64401C4.46023 6.58466 6.90283 4.10194 9.8919 4.11569C12.881 4.12944 15.3006 6.63062 15.2865 9.69388C15.2724 12.7571 12.8273 15.2359 9.8408 15.2222Z" fill="white"/>
                        <path d="M12.9196 12.5219C12.9195 12.6349 12.8945 12.7463 12.8464 12.8477C12.8289 12.8835 12.8088 12.9178 12.7862 12.9504C12.6952 13.037 12.6009 13.1178 12.5033 13.1931C11.8713 13.6884 11.1185 13.991 10.3298 14.0668C9.54099 14.1427 8.74754 13.9888 8.0387 13.6225C7.32987 13.2561 6.73379 12.6919 6.31757 11.9933C5.90135 11.2946 5.6815 10.4893 5.68278 9.66805C5.68406 8.84676 5.90642 8.04207 6.32482 7.34455C6.74322 6.64703 7.34105 6.08438 8.05102 5.71993C8.761 5.35547 9.55493 5.20368 10.3435 5.28165C11.132 5.35961 11.8838 5.66424 12.5142 6.16123C12.5437 6.18419 12.5725 6.2078 12.6014 6.23206C12.7296 6.3533 12.8092 6.52011 12.8244 6.6994C12.8268 6.72258 12.828 6.74589 12.8279 6.7692C12.827 6.9612 12.7526 7.14499 12.621 7.28014C12.4894 7.4153 12.3115 7.49075 12.1263 7.4899C12.1037 7.4898 12.081 7.48969 12.0584 7.48633C11.9661 7.47668 11.8766 7.44788 11.7952 7.40164C11.7376 7.3505 11.6787 7.30262 11.6172 7.25734C11.1876 6.93701 10.681 6.74604 10.1532 6.70553C9.62538 6.66502 9.09701 6.77654 8.62641 7.02777C8.15581 7.279 7.76129 7.66018 7.48641 8.12919C7.21154 8.59821 7.06701 9.13683 7.06878 9.68556C7.07055 10.2343 7.21855 10.7718 7.49645 11.2387C7.77434 11.7056 8.17131 12.0838 8.64352 12.3314C9.11573 12.579 9.64481 12.6865 10.1723 12.642C10.6998 12.5974 11.2053 12.4026 11.6328 12.079C11.725 12.0092 11.8131 11.9338 11.8968 11.8533C11.9948 11.8039 12.1026 11.7787 12.2116 11.7797C12.4002 11.7816 12.5804 11.8607 12.713 11.9997C12.8456 12.1387 12.9199 12.3264 12.9196 12.5219Z" fill="white"/>
                        <path d="M11.7952 7.3943C11.7288 7.35638 11.6687 7.30766 11.6172 7.25C11.6786 7.29528 11.7375 7.34316 11.7952 7.3943Z" fill="white"/>
                        <path d="M12.6027 6.22709C12.5739 6.20282 12.5451 6.17921 12.5156 6.15625C12.5463 6.17767 12.5754 6.20135 12.6027 6.22709Z" fill="white"/>
                        <path d="M11.8957 11.8528C11.8121 11.9333 11.7239 12.0087 11.6318 12.0785C11.7009 11.9823 11.7915 11.9048 11.8957 11.8528Z" fill="white"/>
                        <path d="M19.0938 9.71085C19.0883 11.3836 18.6596 13.0258 17.8503 14.4733C17.8021 14.56 17.7525 14.6467 17.7013 14.7335C17.5932 14.8539 17.4488 14.9326 17.2918 14.9565C17.1348 14.9805 16.9747 14.9483 16.8377 14.8653C16.7008 14.7823 16.5952 14.6533 16.5384 14.4998C16.4816 14.3462 16.4769 14.1771 16.5252 14.0204L16.5095 14.004C17.5229 12.3188 17.9112 10.3094 17.6021 8.34993C17.293 6.39044 16.3076 4.61442 14.8294 3.35253C13.3512 2.09064 11.4809 1.42888 9.56664 1.49047C7.65244 1.55205 5.82481 2.33278 4.42405 3.6873C3.02328 5.04181 2.14483 6.8778 1.95226 8.85343C1.75969 10.8291 2.26612 12.8097 3.37726 14.4265C4.4884 16.0434 6.12852 17.1863 7.99226 17.6425C9.856 18.0986 11.8163 17.837 13.5083 16.9062C13.5676 16.8732 13.6269 16.8415 13.6849 16.8046C13.7484 16.7802 13.815 16.7653 13.8826 16.7605L13.9336 16.7607C14.0253 16.7611 14.1161 16.7801 14.2007 16.8169C14.2853 16.8536 14.3621 16.9073 14.4267 16.9748C14.4913 17.0423 14.5424 17.1224 14.5771 17.2104C14.6119 17.2985 14.6295 17.3927 14.6291 17.4879C14.629 17.5057 14.6289 17.5233 14.6288 17.5407C14.6211 17.6442 14.5921 17.7447 14.5436 17.8356C14.4951 17.9264 14.4283 18.0053 14.3477 18.067C14.2941 18.0987 14.2399 18.1317 14.1857 18.1589C12.8279 18.9153 11.309 19.3068 9.76782 19.2975C4.64643 19.27 0.501601 14.9348 0.526028 9.62542C0.550455 4.31608 4.735 0.0191608 9.85389 0.0427117C14.9728 0.0662627 19.1182 4.40346 19.0938 9.71085Z" fill="white"/>
                        <path d="M21.8386 9.6669C21.8499 7.2148 23.972 5.16597 26.4496 5.17737C27.0782 5.18051 27.6996 5.31315 28.2758 5.56696C28.852 5.82085 29.3709 6.19073 29.8008 6.65401L28.4494 7.86727C27.9491 7.28081 27.2347 6.88396 26.4418 6.88031C24.9955 6.87366 23.7573 8.16334 23.7503 9.67322C23.7433 11.1979 24.9697 12.4998 26.4159 12.5065C27.2088 12.5101 27.9268 12.1198 28.4325 11.5372L29.7727 12.7636C29.3368 13.2207 28.8142 13.5843 28.2361 13.8327C27.658 14.0811 27.0363 14.2092 26.4081 14.2094C23.9304 14.2005 21.8273 12.1198 21.8386 9.6669Z" fill="white"/>
                        <path d="M34.8569 7.386L34.8499 8.89844C33.5221 8.89233 32.6697 9.65103 32.6643 10.8441L32.6503 13.8785L30.8529 13.8702L30.8821 7.51544L32.6771 7.5237L32.6717 8.70395C32.8723 8.29872 33.1847 7.95941 33.5719 7.72586C33.959 7.49239 34.4049 7.37441 34.8569 7.386Z" fill="white"/>
                        <path d="M40.4288 12.0571L41.5789 13.2677C41.0048 13.8739 39.8964 14.275 38.9522 14.2706C37.0379 14.2618 35.5531 12.8215 35.5624 10.8038C35.5715 8.8266 37.0074 7.3972 38.887 7.40585C40.9005 7.41511 42.074 8.98092 42.0626 11.4574L37.4238 11.4361C37.632 12.148 38.189 12.6326 38.9468 12.6361C39.4569 12.6359 40.0794 12.3974 40.4288 12.0571ZM37.4517 10.1015L40.3223 10.1147C40.1518 9.46722 39.6816 9.04653 38.948 9.04316C38.6218 9.03713 38.302 9.13609 38.0339 9.32607C37.7659 9.51606 37.5634 9.78742 37.455 10.1016L37.4517 10.1015Z" fill="white"/>
                        <path d="M50.0401 5.28158L49.9993 14.1607L48.2006 14.1524L48.2049 13.2121C47.9565 13.5558 47.6324 13.8335 47.2594 14.0218C46.8864 14.2102 46.4753 14.3039 46.0602 14.2951C44.3637 14.2873 43.1408 12.8589 43.1501 10.8389C43.1593 8.83216 44.3953 7.41501 46.0919 7.42282C46.5069 7.41796 46.917 7.51537 47.2882 7.70695C47.6594 7.89861 47.9811 8.17897 48.2264 8.52471L48.2414 5.27331L50.0401 5.28158ZM48.2189 10.8622C48.2239 9.77019 47.5513 8.99176 46.6044 8.9874C45.6088 8.98282 44.9043 9.75492 44.8993 10.847C44.8942 11.9522 45.5916 12.7308 46.5872 12.7353C47.5308 12.7397 48.2106 11.9675 48.2157 10.8622L48.2189 10.8622Z" fill="white"/>
                        <path d="M51.4839 5.87449C51.4875 5.68631 51.5538 5.5033 51.6745 5.34836C51.7954 5.19343 51.9654 5.07339 52.1632 5.00339C52.361 4.93332 52.5779 4.91639 52.7867 4.95461C52.9956 4.99284 53.1871 5.08459 53.3373 5.21837C53.4874 5.35214 53.5896 5.52202 53.631 5.70671C53.6723 5.89132 53.6511 6.08263 53.5699 6.25654C53.4888 6.43052 53.3512 6.57941 53.1746 6.68464C52.9979 6.78979 52.79 6.84661 52.5767 6.84799C52.4325 6.84756 52.2899 6.82199 52.1569 6.77279C52.0239 6.72352 51.9031 6.65167 51.8017 6.56119C51.7001 6.4708 51.6198 6.3636 51.5653 6.24574C51.5107 6.12789 51.4831 6.00175 51.4839 5.87449ZM51.5584 7.7033L53.5614 7.71251L53.5326 13.9713L51.5296 13.9621L51.5584 7.7033Z" fill="white"/>
                        <path d="M59.0341 13.8034C58.6146 14.1527 58.0849 14.3472 57.5358 14.3538C56.3934 14.3485 55.5917 13.5407 55.597 12.3877L55.6115 9.25171L54.7196 9.24761L54.7263 7.78979L55.6182 7.7939L55.6263 6.03535L57.3855 6.04345L57.3774 7.80199L58.785 7.80847L58.7783 9.2695L57.3707 9.26302L57.3576 12.1111C57.3557 12.5325 57.6309 12.8312 57.9704 12.8328C58.2217 12.834 58.4734 12.7483 58.5997 12.625L59.0341 13.8034Z" fill="white"/>
                        <path d="M59.7761 9.83877C59.7874 7.38667 61.9095 5.33784 64.3871 5.34924C65.0157 5.35238 65.6371 5.48503 66.2133 5.73883C66.7895 5.99272 67.3084 6.36261 67.7383 6.82589L66.3869 8.03915C65.8866 7.45269 65.1714 7.05583 64.3793 7.05218C62.933 7.04553 61.6948 8.33522 61.6878 9.84509C61.6808 11.3698 62.9072 12.6717 64.3534 12.6783C65.1455 12.682 65.8643 12.2917 66.37 11.7091L67.7102 12.9355C67.2743 13.3926 66.7517 13.7562 66.1736 14.0046C65.5955 14.253 64.9738 14.3811 64.3456 14.3813C61.8679 14.3723 59.7648 12.2917 59.7761 9.83877Z" fill="white"/>
                        <path d="M74.9488 9.99275L74.93 14.074L73.1737 14.0659L73.1907 10.3697C73.1944 9.5755 72.6986 9.02711 71.9753 9.02378C71.2157 9.02028 70.6275 9.56369 70.6243 10.258L70.6068 14.0541L68.7882 14.0457L68.8281 5.375L70.6467 5.38337L70.6327 8.42236C71.0341 7.86601 71.7461 7.49716 72.568 7.50094C73.9635 7.50736 74.9555 8.54132 74.9488 9.99275Z" fill="white"/>
                        <path d="M80.8937 12.2446L82.0446 13.4552C81.4696 14.0614 80.3621 14.4625 79.4171 14.4581C77.5028 14.4493 76.018 13.009 76.0273 10.9913C76.0364 9.0141 77.4722 7.5847 79.3518 7.59335C81.3694 7.60263 82.5388 9.16842 82.5275 11.6449L77.8911 11.6236C78.0992 12.3355 78.6563 12.8201 79.4189 12.8236C79.9217 12.8234 80.5443 12.5849 80.8937 12.2446ZM77.9166 10.289L80.788 10.3023C80.6167 9.65472 80.1473 9.23404 79.4129 9.23066C79.0868 9.22471 78.7668 9.32375 78.4988 9.51374C78.2308 9.70372 78.0286 9.975 77.9198 10.2891L77.9166 10.289Z" fill="white"/>
                        <path d="M87.5372 10.5557L90.0788 14.1381L87.9189 14.1281L86.285 11.715L85.4841 12.5421L85.4769 14.1129L83.6008 14.1042L83.6406 5.4375L85.5168 5.44613L85.4937 10.4658L87.9742 7.71252L90.1333 7.72246L87.5372 10.5557Z" fill="white"/>
                        <path d="M46.7893 5.62857C46.6551 5.53323 46.5337 5.4107 46.4297 5.26562C46.5538 5.37951 46.6729 5.49994 46.7893 5.62857Z" fill="white"/>
                        <path d="M48.6057 3.11294C48.4867 2.98862 48.3677 2.86764 48.2461 2.75C48.3726 2.85973 48.4928 2.98103 48.6057 3.11294Z" fill="white"/>
                    </svg>
                </div>
            </div>
        </PDFView>
    )
}

const TableDropdown = ({item, expand}) => {
    const [show, setShow] = useState(false)

    useEffect(()=>{
        expand(show)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
        <div>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setShow(false)
                }}
            >
                <img 
                    src={dropdown} 
                    alt="dropdown" 
                    className={clsx(
                        "w-[30px] cursor-pointer mx-2 transition-all transform absolute top-2.5 right-0",
                        show && "rotate-180"
                    )} 
                    onClick={()=>{
                        setShow(!show)
                    }} 
                />
                {
                    show &&
                    <div className="position grid grid-cols-4 shadow-lg absolute left-[10px] right-[10px] top-[55px] rounded-[4px] z-10 border border-solid border-emerald-600 bg-white px-2.5 pt-2.5">
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Product Name</p>
                            <p className="text-cc-black-3">{item?.mandateId?.productId?.name||"Not available"}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Account Name</p>
                            <p className="text-cc-black-3">{item?.accountName||"Not available"}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Amount Triggered</p>
                            <p className="text-cc-black-3">{currencyFormatter(item.totalAmount||item.loanAmount||0)}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Amount Collected</p>
                            <p className="text-cc-black-3">{currencyFormatter(item?.amountCollected || 0)}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Date of Collection</p>
                            <p className="text-cc-black-3">{dateFormatter(item.collectedOn, "dd-mm-yyyy")} {timeFormatter(item.dueDate)}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Mode of Collection</p>
                            <p className="text-cc-black-3">{item?.collectionType||"Not available"}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Status</p>
                            <p className="text-cc-black-3">{item.status}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Advance Notice Sent</p>
                            <p className="text-cc-black-3">{item?.advanceNotification ? "True":"False"}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Date of Notice</p>
                            <p className="text-cc-black-3">Not available</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Account Number</p>
                            <p className="text-cc-black-3">{item?.accountNumber||"Not available"}</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Bank</p>
                            <p className="text-cc-black-3">Not available</p>
                        </div>
                        <div className="text-sm pb-3">
                            <p className="text-cc-grey-4">Mandate Ref</p>
                            <div className="flex items-center gap-x-1">
                                <p className="text-cc-black-3">{item?.mandateId?.reference}</p>
                                <img src={copy} alt="copy" className="cursor-pointer w-[20px]" onClick={()=>copyText(item?.mandateId?.reference, "Mandate reference copied!")} />
                            </div>
                        </div>
                    </div>
                }
            </OutsideClickHandler>
        </div>
    )
}

export default Collections