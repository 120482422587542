import {Row, Col} from "react-bootstrap";
import { currencyFormatter, getDateFromDayinYear } from "utils/helpers";

import lastDeposit from "images/svg/income/last-deposit.svg";
import lastWithdrawal from "images/svg/income/last-withdrawal.svg";
import highestBalance from "images/svg/income/highest-balance.svg";
import lowestBalance from "images/svg/income/lowest-balance.svg";
import averageBalance from "images/svg/income/average-balance.svg";
import AverageValues from "./AverageValue";


export function TransactionPatterns ({data, forYear}) {
    const firstMonth = Object.entries(forYear)?.reverse()?.[0]?.[0];
    const getYear = firstMonth.substring(firstMonth.length-4, firstMonth.length);

    return (
        <div style={{marginTop:'30px'}}>
            <Row>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Highest Deposit</p>
                            <img src={lastDeposit} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.maxCredit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.maxCredit>data?.averageMaxCredit} 
                            value={`${currencyFormatter((data?.averageMaxCredit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Average Deposit</p>
                            <img src={lastDeposit} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.meanCredit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.meanCredit>data?.averageMeanCredit} 
                            value={`${currencyFormatter((data?.averageMeanCredit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Lowest Deposit</p>
                            <img src={lastDeposit} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.min_crebit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.min_crebit>data?.averageMinCredit} 
                            value={`${currencyFormatter((data?.averageMinCredit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Highest Withdrawal</p>
                            <img src={lastWithdrawal} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.maxDebit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.maxDebit>data?.averageMaxDebit} 
                            value={`${currencyFormatter((data?.averageMaxDebit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Average Withdrawal</p>
                            <img src={lastWithdrawal} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.meanDebit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.meanDebit>data?.averageMeanDebit} 
                            value={`${currencyFormatter((data?.averageMeanDebit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Lowest Withdrawal</p>
                            <img src={lastWithdrawal} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.minDebit/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.minDebit>data?.averageMinDebit} 
                            value={`${currencyFormatter((data?.averageMinDebit||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Highest Balance</p>
                            <img src={highestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{getDateFromDayinYear(getYear, data?.max_bal_day_list_dict?.[0] || 0)}</p>
                        <AverageValues 
                            positive={data?.averageHighestBalance>0} 
                            value={`${currencyFormatter((data?.averageHighestBalance||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Average Daily Balance</p>
                            <img src={averageBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.averageDailyBal/100 || 0)}</p>
                        <AverageValues 
                            positive={data?.averageDailyBal>data?.averageADailyBal} 
                            value={`${currencyFormatter((data?.averageADailyBal||0)/100)}`} 
                        />
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Week With Lowest Balance</p>
                            <img src={lowestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">Week {data?.min_bal_week_list_dict?.[0] || 'not available'}</p>
                        <AverageValues 
                            positive={data?.averageLowestBalance>0} 
                            value={`${currencyFormatter((data?.averageLowestBalance||0)/100)}`} 
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}