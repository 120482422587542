import clsx from "clsx";
import bank from "images/svg/customer/bank.svg";
import { currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import { BsArrowRight } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { RecovaService } from "services/recova.service";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BankService } from "services/bank.service";
import { toast } from "react-toastify";
import loader from "images/gif/white-loader.gif";


export default function MandateSummary () {
    const [showModal, setShowModal] = useState(false);
    let navigate = useNavigate();
    const [data, setData] = useState(null)
    const [bankAuth, setBankAuth] = useState({
        microDeposit: true,
        eSignature: false,
    });
    const [userData, setUserData] = useState({})
    const [loading, setLoading] = useState(false)
    const [storedData, setStoredData] = useState({})
    const [searchParams] = useSearchParams();
    const [bankAuthLoading, setBankAuthLoading] = useState({
        microDeposit: false,
        eSignature: false,
    })

    useEffect(()=>{
        try {
            const stored = JSON.parse(localStorage.getItem("cc_dd_data"))
            const bank_ = JSON.parse(
                sessionStorage.getItem(
                        searchParams.get("account")==="primary" ? "cc_dd_selectedPrimaryBank":"cc_dd_selectedBackupBank"
                    )
                );
            setStoredData(stored)
            setUserData(bank_)
            RecovaService.getMandateById(stored.mandateId)
                .then((response) => response.data)
                .then((res) => {
                    if(res.status===true){
                        setData(res?.data?.mandate)
                        sessionStorage.setItem("mandateInfo", JSON.stringify(res.data))
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                })
            setBankAuthLoading({...bankAuthLoading, eSignature: true})
            BankService.getAllBankLogo()
                .then(resp=>{
                    setBankAuthLoading({...bankAuthLoading, eSignature: false})
                    setBankAuth({
                        ...bankAuth,
                        eSignature: resp?.data?.find(o => o.code === bank_.bankCode)?.signedMandate || false,
                    })
                })
            getMicroDepositBanks(bank_)
        } catch (err) {
            setBankAuthLoading({...bankAuthLoading, eSignature: false})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getMicroDepositBanks = (banks) => {
        setBankAuthLoading({...bankAuthLoading, microDeposit: true})
        RecovaService.getMicrodepositBanklist()
            .then((response) => response.data)
            .then((res) => {
                // setBankAuthLoading({...bankAuthLoading, microDeposit: false})
                if(res.status===true){
                    setBankAuth({
                        ...bankAuth,
                        microDeposit: res?.data?.find(o => o.bank_code === banks.bankCode)?.direct_debit || false,
                    })
                }
            })
            .catch((err) => {
                // setBankAuthLoading({...bankAuthLoading, microDeposit: false})
            })
    }

    const handleSubmit = (cond, type) => {
        const values = {
            appId: storedData.appId,
            businessId: storedData.businessId,
            mandateId: storedData.mandateId,
            accountNumber: userData.accountNumber,
            bankCode: userData.bankCode,
            address: JSON.parse(sessionStorage.getItem("cc_dd_userBVNDetails"))?.residentialAddress||"Nigeria",
        }
        if(type==="micro-deposit" && cond){
            setLoading(true);
            const payload = JSON.stringify(values)
            RecovaService.microDepositMandate(payload)
                .then((response) => response.data)
                .then((res) => {
                    if(res.status===true){
                        setLoading(false);
                        sessionStorage.setItem("mandateReview", JSON.stringify({
                            mandate: res?.data?.mandate || data,
                            transfer_destinations: res?.data?.transfer_destinations || []
                        }))
                        navigate(`/customer/direct-debit/${type}?account=${searchParams.get("account")}`)
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    handleRequestErrors(err)
                })
        } else if (type==="e-signature" && cond) {
            navigate(`/customer/direct-debit/${type}?account=${searchParams.get("account")}`)
        }
    }

    return (
        <div className="relative">
            <div 
                className={clsx(
                    "bg-white py-10 px-4 min-h-[80vh] overflow-hidden",
                    showModal && "blur pointer-events-none",
                )}
            >
                <img src={bank} alt="bank" className="w-[70px] h-[70px]" />
                <h4 className="text-[30px] font-bold my-6 text-center">Mandate Confirmation</h4>
                <p className="text-[20px] text-center">Select from your list of banks linked to your BVN</p>
                <p className="text-[40px] font-bold text-center">{currencyFormatter(data?.totalAmountDue || 0)}</p>
                <div className="grid grid-cols-2 text-[#667085] my-7">
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">Product Name</p>
                        <p className="text-sm capitalize">{data?.productId?.name}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">Collection Mode</p>
                        <p className="text-sm capitalize">{data?.collectionMode}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">BVN</p>
                        <p className="text-sm">{data?.bvn}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">Frequency</p>
                        <p className="text-sm">{data?.frequency}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">Start Date</p>
                        <p className="text-sm">{dateFormatter(data?.startDate, 'dd/mm/yyyy')}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">First Repayment Percentage</p>
                        <p className="text-sm">{data?.firstRepaymentPercentage}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">End Date</p>
                        <p className="text-sm">{dateFormatter(data?.endDate, 'dd/mm/yyyy')}</p>
                    </div>
                    <div className="border-t-[1px] border-b-[1px] p-2.5">
                        <p className="font-bold text-base">Reference</p>
                        <p className="text-sm">{data?.reference}</p>
                    </div>
                </div>
                <p className="mx-auto block max-w-[300px] text-[20px] text-center">To setup this mandate, you need to authorise CreditChek Direct Debit Platform.</p>
                <div className="relative">
                    {bankAuthLoading.microDeposit && <img src={loader} alt="loader" className='absolute z-[90] top-[calc(50%-12px)] left-[calc(50%-12px)] w-[24px]' />}
                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        onClick={()=>{
                            if(!bankAuthLoading.microDeposit){
                                setShowModal(true)
                            }
                        }} 
                        className={clsx(
                            "mt-3 cursor-pointer w-[50px] h-[50px] mx-auto block",
                            bankAuthLoading.microDeposit && "opacity-50"
                        )}
                        viewBox="0 0 68 60" 
                        fill="none"
                    >
                        <ellipse cx="33.7931" cy="30" rx="33.7931" ry="30" fill="#0046E6"/>
                        <path d="M48.0966 31.6621L37.1246 42.6302L33.4677 38.9643L38.1953 34.2173H20V29.0449H38.1953L33.4664 24.3328L37.1233 20.6914L48.0966 31.6621Z" fill="#FCFCFD"/>
                    </svg>
                </div>
            </div>
            <div 
                style={{transition: "2s"}}
                className={clsx(
                    "absolute w-full rounded-t-lg box-border bg-white",
                    showModal ? "bottom-0":"-bottom-[400px]"
                )}
            >
                <MdCancel className="absolute top-3 right-3 scale-[1.4] cursor-pointer" onClick={()=>setShowModal(false)} />
                <div className="p-4">
                    <img src={userData.logo ?? bank} alt="bank" className="block rounded-full mx-auto w-[60px] h-[60px] transition ease-in-out !duration-[5000ms]" />
                    <p className="text-base text-center mt-1 mb-3">Choose Authorization Method</p>
                    <div
                        className={clsx(
                            "flex items-center gap-x-2",
                            // if bank accept mthd
                            bankAuth.microDeposit ? "cursor-pointer":"blur-[1px] cursor-default",
                            loading && "!cursor-wait",
                        )}
                        data-tooltip-id="micro-deposit"
                        data-tooltip-place="top"
                        onClick={()=>{
                            if(!loading){
                                handleSubmit(bankAuth.microDeposit, "micro-deposit")
                            }
                        }} 
                    >
                        <svg className="shrink-0" width="50" height="50" viewBox="0 0 52 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <ellipse cx="25.908" cy="23" rx="25.908" ry="23" fill="#0046E6"/>
                            <g clip-path="url(#clip0_15825_90739)">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4475 22.6107C24.5026 22.6107 22.9209 20.8914 22.9209 18.7773C22.9209 16.6633 24.5026 14.944 26.4475 14.944C28.3925 14.944 29.9742 16.6633 29.9742 18.7773C29.9742 20.8914 28.3925 22.6107 26.4475 22.6107ZM29.7608 23.2556C30.9652 22.2024 31.7375 20.5886 31.7375 18.7773C31.7375 15.6014 29.3694 13.0273 26.4475 13.0273C23.5257 13.0273 21.1575 15.6014 21.1575 18.7773C21.1575 20.5886 21.9299 22.2024 23.1342 23.2556C19.9082 24.5733 17.6309 27.8288 17.6309 32.194H19.3942C19.3942 27.4023 22.5585 24.5273 26.4475 24.5273C30.3366 24.5273 33.5009 27.4023 33.5009 32.194H35.2642C35.2642 27.8288 32.9868 24.5733 29.7608 23.2556Z" fill="#FCFCFD"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_15825_90739">
                                    <rect width="17.6333" height="19.1667" fill="white" transform="translate(17.6328 13.0352)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div>
                            <div className="flex items-center gap-x-1">
                                <p className="font-medium text-base">Authorize via Micro-deposit</p>
                                <BsArrowRight/>
                            </div>
                            <p className="text-sm font-medium text-[#98A2B3]">Make a transfer of a token amount to complete eMandate setup</p>
                        </div>
                    </div>
                    {!bankAuth.microDeposit && <MyToolTip id="micro-deposit"/>}
                    <div 
                        className={clsx(
                            "flex mt-3 items-center gap-x-2",
                            // if bank accept mthd
                            // bankAuth.eSignature ? 
                            false ? "cursor-pointer":"blur-[1px] cursor-default",
                            loading && "!cursor-wait",
                        )}
                        data-tooltip-id={
                            // bankAuth.eSignature
                            false?"":"eSignature"}
                        data-tooltip-place="top"
                        onClick={()=>{
                            // if(!loading){
                            //     handleSubmit(bankAuth.eSignature, "e-signature")
                            // }
                        }}
                    >
                        <svg className="shrink-0" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 53 47" fill="none">
                            <ellipse cx="26.4713" cy="23.5" rx="26.4713" ry="23.5" fill="#0046E6"/>
                            <path d="M18.7628 18.0618C18.5342 17.8492 18.1634 17.8492 17.9348 18.0618L16.6206 19.2825C16.3921 19.4951 16.3921 19.8392 16.6206 20.0518L17.4724 20.8432L19.6147 18.8531L18.7628 18.0618Z" fill="#FCFCFD"/>
                            <path d="M28.2807 28.8955L27.5504 26.2266L25.4082 28.2169L28.2807 28.8955Z" fill="#FCFCFD"/>
                            <path d="M27.1138 25.8186L20.0055 19.2148L17.8633 21.2052L24.9714 27.8089L27.1138 25.8186ZM20.0637 20.0654L26.2567 25.8189L25.7699 26.271L19.5769 20.5178L20.0637 20.0654Z" fill="#FCFCFD"/>
                            <path d="M25.7439 18.7578H24.5312V19.833H25.7439V18.7578Z" fill="#FCFCFD"/>
                            <path d="M25.7439 21.375H24.5312V22.4499H25.7439V21.375Z" fill="#FCFCFD"/>
                            <path d="M34.2014 18.7578H27.0527V19.833H34.2014V18.7578Z" fill="#FCFCFD"/>
                            <path d="M34.2014 21.375H27.0527V22.4499H34.2014V21.375Z" fill="#FCFCFD"/>
                            <path d="M34.2017 23.9922H28.3711V25.0671H34.2017V23.9922Z" fill="#FCFCFD"/>
                            <path d="M34.2055 14.0977H24.5296C22.6575 14.0977 21.1348 15.5121 21.1348 17.2514V18.5073L22.3917 19.6753V17.2514C22.3917 16.1544 23.3491 15.2653 24.5296 15.2653H34.2055C35.3867 15.2653 36.3435 16.1544 36.3435 17.2514V27.0007H33.0348C32.0507 27.0007 31.2534 27.7421 31.2534 28.656V31.73H24.5296C23.3491 31.73 22.3917 30.8409 22.3917 29.7439V28.3212L22.1341 28.2603L22.3917 28.021V27.169L21.1348 26.0013V29.7439C21.1348 31.4829 22.6575 32.8977 24.5296 32.8977H31.2534H31.7738L32.1421 32.5556L37.2324 27.8267L37.6004 27.4847V27.0007V17.2514C37.6004 15.5121 36.0777 14.0977 34.2055 14.0977Z" fill="#FCFCFD"/>
                        </svg>
                        <div>
                            <div className="flex items-center gap-x-1">
                                <p className="font-medium text-base">Authorize via eSignature</p>
                                <BsArrowRight/>
                            </div>
                            <p className="text-sm font-medium text-[#98A2B3]">Upload or sign with your signature matching the record with your bank to complete setup</p>
                        </div>
                    </div>
                    {
                    // !bankAuth.eSignature && 
                    true && <MyToolTip id="eSignature"/>}
                </div>
            </div>
        </div>
    )
}


const MyToolTip = ({id}) => {
    return(
        <Tooltip 
            id={id}
            render={()=>(
                <div className="text-xs w-[200px]">
                    <MdCancel className="scale-[2] mb-1 mx-auto block text-[#DF5C4E]"/>
                    <p>This bank is not currently covered. Kindly use the other method to continue.</p>
                </div>
            )}
        />
    )
}