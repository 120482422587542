
import SelectBank from "./selectBank";
import MandateSummary from "./mandateSummary";
import { BsArrowLeft } from "react-icons/bs";
import DirectDebitHome from "./home";
import MicroDeposit from "./micro-deposit";
import ESignature from "./eSignature";
import Review from "./review";
import RepaymentBreakdown from "./repaymentBreakdown";
import FinishDirectDebit from "./finish";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";


export default function DirectDebit () {
    let navigate = useNavigate();
    const location = useLocation()

    return (
        <div className="max-w-[500px] relative min-h-[80vh] mx-auto block rounded-[12px] overflow-hidden">
            <Helmet>
                <title>CreditChek Mandate Setup</title>
            </Helmet>
            {
                location?.pathname!=="/customer/direct-debit/completed" &&
                <BsArrowLeft className="z-[3] absolute top-[20px] scale-[1.5] left-[20px] cursor-pointer" onClick={()=>navigate(-1)} />
            }
            <Routes>
                <Route path="/:mandateId/:businessId" element={<DirectDebitHome />}/>
                <Route path="/select-bank" element={<SelectBank />}/>
                <Route path="/mandate" element={<MandateSummary />}/>
                <Route path="/micro-deposit" element={<MicroDeposit/>}/>
                <Route path="/e-signature" element={<ESignature />}/>
                <Route path="/review" element={<Review />}/>
                <Route path="/repayment/:mandateId/:businessId" element={<RepaymentBreakdown />}/>
                <Route path="/completed" element={<FinishDirectDebit/>}/>
            </Routes>
        </div>
    )
}


// mandateId - 65b3ada683e37ece93963450
// businessId - 6295e8b91a4f510013818fc1


// height: 100vh;
// background:   repeating-linear-gradient(
//     315deg,
//     #023fcd,
//     #023fcd 1px,
//     #0046E6 1px,
//     #0046E6 10px
// );
// padding: 20px;
// color: white;