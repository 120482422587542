import clsx from "clsx";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";


export default function AverageValues ({
    positive, value,
}) {
    return (
        <div className="flex items-center gap-x-1 mt-1.5">
            {
                positive ? <IoMdArrowDropup  className="text-cc-green-1 scale-[1.3]" /> : <IoMdArrowDropdown className="text-cc-red-10 scale-[1.3]" />
            }
            <p className={clsx("text-xs", positive?"text-cc-green-1":"text-cc-red-10")}>{value} (Average)</p>
        </div>
    )
}