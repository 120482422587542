
import { useEffect, useState} from "react";
import Table from "components/Table";
import { handleRequestErrors } from "utils/helpers";
import OutsideClickHandler from 'react-outside-click-handler';
import clsx from "clsx";

import smiley from "images/svg/recova/empty.svg";
import dropdown from "images/svg/table_dropdown.svg";
import customerIcon from "images/svg/recova/customer-mandate.svg";
import Button from "components/shared/Button";
import { RecovaService } from "services/recova.service";
// import Filter from "components/Table/Filter";


export default function RegisteredCustomers ({goToCreate}) {
    const [viewMore, setViewMore] = useState(false);
    const [borrowers, setBorrowers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("")
    const [displayData, setDisplayData] = useState([])

    useEffect(()=>{
        setIsLoading(true)
        RecovaService.getRecovaCustomers()
        .then(response=> response.data)
        .then(res => {
            setIsLoading(false)
            if(res.status){
                setBorrowers(res.data)
                setDisplayData(res.data)
            } else {
                
            }
        })
        .catch((err) => {
            setIsLoading(false)
            handleRequestErrors(err, true)
        });
    }, [])

    useEffect(() => {
        if (searchValue===""){
            setDisplayData(borrowers)
        } else {
            const filteredData = borrowers?.filter((item) => item?.fullname.toLowerCase().includes(searchValue.toLowerCase()));
            setDisplayData(filteredData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue])
    
    const handleChange = (e) => {
        setSearchValue(e.target.value)
    }

    return(
        <div>
            <Table
                titleIcon={customerIcon}
                tableTitle={"Registered Customers"}
                data={displayData}
                loading={isLoading}
                hideSearch={false}
                trClassName={"relative"}
                searchPlaceHolder={"Search Debtor’s name..."}
                onSearchChange={handleChange}
                searchContainerClass="!w-[400px]"
                searchInputClass="!text-sm placeholder:!text-[#667085]"
                searchIconPosition="left"
                tableClassName={clsx(
                    "2xl:min-w-[1000px]",
                    viewMore ? "bg-[#F9FAFB] mb-[200px]" : "bg-[#F9FAFB]"
                )}
                // slot={
                //     <Filter
                //         filterValue={"all"}
                //         onChange={(v)=>console.log(v)}
                //         options={[
                //             { label: "Filter", value: "all", showOnList: false },
                //             { label: "All", value: "all", showOnList: true },
                //         ]}
                //         hideCustom={true}
                //     />
                // }
                rows={[
                    {
                        header:'Customer Name',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] capitalize">
                                {item?.fullname||"---"}
                            </p>
                        ),
                    },
                    {
                        header:'Mobile',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                {item?.phone || item?.phones?.[0] || "---"}
                            </p>
                        ),
                    },
                    {
                        header:'Email',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4 !lowercase">
                               {item?.email || item?.emails?.[0] || "---"}
                            </p>
                        ),
                    },
                    {
                        header:'BVN',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                {item?.bvn||"---"}
                            </p>
                        ),
                    },
                    {
                        header:'Date of Birth',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                {item?.dateOfBirth||"---"}
                            </p>
                        ),
                    },
                    {
                        header:'Marital Status',
                        view: (item) => (
                            <p className="py-[10px] pl-[10px] pr-[5px] text-cc-grey-4">
                                {item?.maritalStatus||"---"}
                            </p>
                        ),
                    },
                    {
                        header:'Action',
                        view: (item) => (
                            <div className="w-[110px] m-2.5">
                                <Button
                                    isValid={true}
                                    type="button"
                                    text="Create Mandate"
                                    onClick={()=>goToCreate(item?.bvn||"---")}
                                    className="bg-cc-primary text-sm px-2 !py-1.5 !text-xs"
                                />
                            </div>
                        ),
                    },
                    {
                        header:'',
                        view: (item) => (
                            <TableDropdown item={item} expand={(val)=>setViewMore(val)} />
                        ),
                    },
                ]}
                emptyTable={
                    <div className="flex items-center my-[120px] mx-[20px] justify-center">
                        <p className="text-cc-grey-4 mr-3">Oops...No borrowers found!</p>
                        <img src={smiley} alt="" className="w-[40px]" />
                    </div>
                }
            />
        </div>
    )
}

const TableDropdown = ({item, expand}) => {
    const [show, setShow] = useState(false)

    useEffect(()=>{
        expand(show)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    return (
        <div>
            <OutsideClickHandler
                onOutsideClick={() => {
                    setShow(false)
                }}
            >
                <img 
                    src={dropdown} 
                    alt="dropdown" 
                    className={clsx(
                        "w-[30px] cursor-pointer mx-2 transition-all transform",
                        show && "rotate-180"
                    )} 
                    onClick={()=>{
                        setShow(!show)
                    }} 
                />
                {
                    show &&
                    <div className="position shadow-lg absolute left-[10px] right-[10px] top-[55px] rounded-[4px] z-10 border border-solid border-emerald-600 bg-white px-2.5 pt-2.5">
                        <div className="grid grid-cols-4">
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Customer Name</p>
                                <p className="text-cc-black-3">{item?.fullname||"---"}</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Mobile</p>
                                <p className="text-cc-black-3">{item?.phone || item?.phones?.[0] || "---"}</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Email Address</p>
                                <p className="text-cc-black-3 !lowercase">{item?.email || item?.emails?.[0] || "---"}</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">BVN</p>
                                <p className="text-cc-black-3">{item?.bvn||"---"}</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Date of Birth</p>
                                <p className="text-cc-black-3">{item?.dateOfBirth||"Not available"}</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Marital Status</p>
                                <p className="text-cc-black-3">{item?.maritalStatus||"Not available"}</p>
                            </div>
                        </div>
                        <p className="py-2.5 text-cc-grey-4 font-semibold">KYC Consent</p>
                        <div className="grid grid-cols-4">
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">BVN Consent Status</p>
                                <p className="text-cc-black-3">Not available</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Date deactivated</p>
                                <p className="text-cc-black-3">Not available</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">Date of Renewal</p>
                                <p className="text-cc-black-3">Not available</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">KYC Validity</p>
                                <p className="text-cc-black-3">Not available</p>
                            </div>
                            <div className="text-sm pb-3">
                                <p className="text-cc-grey-4">BVN Linked Banks</p>
                                <p className="text-cc-black-3">Not available</p>
                            </div>
                        </div>
                    </div>
                }
            </OutsideClickHandler>
        </div>
    )
}