import Table from "components/Table";
import view from "images/svg/recova/view.svg"
import copy from "images/svg/recova/copy.svg"
import smiley from "images/svg/recova/empty.svg";
// import consentDownload from "images/svg/recova/consent-download.svg"
import clsx from "clsx";
import { copyText, currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import { useEffect, useState } from "react";
import { RecovaService } from "services/recova.service";
import ShareMandateModal from "./ShareMandateModal";
import { useUser } from "hooks/redux.hook";
import { useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import ModalLayout from "../../layout/ModalLayout";
import { FaCheckCircle } from "react-icons/fa";
import Button from "components/shared/Button";
import Filter from "components/Table/Filter";
import play from "images/svg/recova/play.svg"
import InitiateDebit from "./InitiateDebit";
import { Tooltip } from "react-tooltip";
import TableDropdown from "./TableDropdown";


export default function HistoryTable ({title, icon, reloadHistory}) {
    const [history, setHistory] = useState([])
    const [historyLoading, setHistoryLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [mandateData, setMandateData] = useState(null)
    const {b2b_data} = useUser()
    const appId = useSelector(state => state?.app?.active?._id)
    const [activeAction, setActiveAction] = useState(null)
    const [viewData, setViewData] = useState(false)
    const [showStatusSuccess, setShowStatusSuccess] = useState({
        show: false,
        type: "",
        loading: false,
        id: "",
    })
    const [searchTerm, setSearchTerm] = useState("")
    const [filterTerm, setFilterTerm] = useState("all");
    const [showInitiatDebitModal, setShowInitiatDebitModal] = useState(false)

    useEffect(()=>{
        getMandates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadHistory, searchTerm, filterTerm])

    const getMandates = () => {
        setHistoryLoading(true)
        RecovaService.getAllMandates(searchTerm, filterTerm)
            .then(response=> response.data)
            .then(res => {
                setHistoryLoading(false)
                if(res.status===true) {
                    setHistory(res?.data?.reverse())
                }
            })
            .catch((err) => {
                setHistoryLoading(false)
                handleRequestErrors(err, true)
            });
    }

    const updateMandateStatus = (type, id) => {
        setShowStatusSuccess({
            ...showStatusSuccess,
            type: type,  
            loading: true
        })
        const payload = JSON.stringify({
            mandateId: id
        })
        RecovaService.updateMandateStatus(payload, type)
            .then(response=> response.data)
            .then(res => {
                setShowStatusSuccess({...showStatusSuccess, loading: false})
                if(res.status) {
                    setShowStatusSuccess({show: true, type: type, loading: false, id: id})
                    toast.success(`Mandate ${type}${type==="cancel"?"led":"d"} successfully.`)
                    getMandates()
                }
            })
            .catch((err) => {
                setShowStatusSuccess({...showStatusSuccess, loading: false})
                handleRequestErrors(err)
            });
    }

    const  handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <div>
            <Table
                tableTitle={title}
                searchPlaceHolder={"Search by BVN and MandateRef"}
                onSearchChange={handleChange}
                searchContainerClass="min-w-[400px]"
                searchInputClass="!text-sm !text-cc-grey-4"
                searchIconFirst={true}
                slot={
                    <Filter
                        filterValue={filterTerm}
                        onChange={(v)=>setFilterTerm(v)}
                        options={[
                            { label: "Filter", value: "all", showOnList: false },
                            { label: "All", value: "all", showOnList: true },
                            { label: "Pending", value: "pending", showOnList: true },
                            { label: "Active", value: "active", showOnList: true },
                            { label: "Paused", value: "paused", showOnList: true },
                            { label: "Deactivated", value: "deactivated", showOnList: true },
                        ]}
                        hideCustom={true}
                        extraClass={"!text-sm"}
                        optionClass={"!text-sm"}
                        containerClass="!h-[40px] !rounded-[8px] pt-1"
                    />
                }
                titleIcon={icon}
                data={history}
                loading={historyLoading}
                hideSearch={false}
                tableClassName={clsx(
                    "min-w-[1100px]",
                    viewData && "mb-[200px]",
                    !!activeAction && "mb-[80px]",
                )}
                trClassName="relative"
                tableEmpty={
                    <div className="flex items-center my-[120px] mx-[20px] justify-center">
                        <p className="text-cc-grey-4 mr-3">No mandates exist yet!</p>
                        <img src={smiley} alt="" className="w-[40px]" />
                    </div>
                }
                rows={[
                    {
                        header:'Product Name',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] capitalize text-sm font-medium pr-[5px]">
                                {item?.productId?.name||"Not available"}
                            </span>
                        ),
                    },
                    {
                        header:'BVN',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] text-sm font-medium pr-[5px]">
                                {item?.bvn}
                            </span>
                        ),
                    },
                    {
                        header:'Amount Due',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] text-sm text-cc-grey-4 pr-[5px]">
                                {currencyFormatter(item?.totalAmountDue||0)}
                            </span>
                        ),
                    },
                    {
                        header:'Creation Date',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px] text-sm text-cc-grey-4">{dateFormatter(item?.createdAt, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'Start Date',
                        view: (item) => (
                            <span className="lowercase py-[10px] pl-[10px] text-sm pr-[5px] text-cc-grey-4">{dateFormatter(item?.startDate, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'End Date',
                        view: (item) => (
                            <span className="lowercase py-[10px] pl-[10px] text-sm pr-[5px] text-cc-grey-4">{dateFormatter(item?.endDate, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'Collection Mode',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px] text-sm text-cc-grey-4 capitalize">{item?.collectionMode}</span>
                        ),
                    },
                    {
                        header: <div className="flex items-center gap-1">
                            <p>Status</p>
                            <div className="cursor-pointer" onClick={()=>getMandates()}>                
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.05802 9.4375C1.01973 9.13037 1 8.81753 1 8.5C1 4.35786 4.35786 1 8.5 1C10.8443 1 12.9374 2.07557 14.3127 3.76015M14.3127 3.76015V1M14.3127 3.76015V3.81243L11.5002 3.8125M15.942 7.5625C15.9803 7.86963 16 8.18247 16 8.5C16 12.6422 12.6422 16 8.5 16C6.25995 16 4.24927 15.018 2.875 13.4609M2.875 13.4609V13.1875H5.6875M2.875 13.4609V16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>,
                        view: (item) => (
                            <div className="flex items-center text-sm pl-2.5">
                                <div 
                                    className={clsx(
                                        "w-2.5 h-2.5 rounded-full",
                                        item?.status==="pending" ? "bg-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "bg-cc-green-2" : "bg-cc-red-8"
                                    )}
                                />
                                <p 
                                    className={clsx(
                                        "ml-1.5 font-bold",
                                        item?.status==="pending" ? "text-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "text-cc-green-2" : "text-cc-red-8"
                                    )}
                                >{item?.status}</p>
                            </div>
                        ),
                    },
                    {
                        header:'',
                        view: (item) => (
                            <div className="flex my-2.5 mr-3">
                                <img 
                                    src={view} 
                                    alt="view" 
                                    className={clsx(
                                        "cursor-pointer mr-2.5",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50"
                                    )}
                                    onClick={()=>{
                                        if(item.status==="deactivated"||item.status==="closed"){
                                            toast.info(`This mandate has been ${item.status}`)
                                        } else {
                                            setMandateData(item);
                                            setShowModal(true)
                                        }
                                    }} 
                                />
                                <img 
                                    src={copy} 
                                    alt="copy" 
                                    className={clsx(
                                        "cursor-pointer mr-2.5",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50"
                                    )}
                                    onClick={()=>{
                                        if(item.status==="deactivated"||item.status==="closed"){
                                            toast.info(`This mandate has been ${item.status}`)
                                        } else {
                                            copyText(
                                                `${window.location.origin}/customer/direct-debit/${item?._id}/${b2b_data.user.businessId}?appId=${appId}&account=${item?.status==="active"||item?.consents?.length>0?"backup":"primary"}`, 
                                                "Mandate link copied!"
                                            )
                                        }
                                    }} 
                                />
                                <img 
                                    src={play} 
                                    alt="play" 
                                    className={clsx(
                                        "cursor-pointer scale-[1.2] w-3.5",
                                        (item.collectionMode==="auto"||item.status==="deactivated"||item.status==="closed")&&"hidden"
                                    )}
                                    data-tooltip-id={"initiate-debit"}
                                    onClick={()=>{
                                        if(item.collectionMode!=="auto"||item.status!=="deactivated"||item.status!=="closed"){
                                            setShowInitiatDebitModal(true)
                                            setMandateData(item);
                                        }
                                    }} 
                                />
                                <Tooltip 
                                    id={"initiate-debit"} 
                                    place="right"
                                    render={()=>(
                                        <div className="text-xs text-white w-[80px]">
                                            <p>Initiate Debit</p>
                                        </div>
                                    )} 
                                />
                                <div 
                                    className={clsx(
                                        "cursor-pointer w-[18px] mx-2 relative",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50" 
                                    )}
                                >
                                    <div 
                                        className="py-0.5" 
                                        onClick={()=>{
                                            if(item.status==="deactivated"||item.status==="closed"){
                                                toast.info(`This mandate has been ${item.status}`)
                                            } else {
                                                setActiveAction(item._id)
                                            }
                                        }}>
                                        <svg className="my-2" width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.875 0C0.838623 0 0 0.894532 0 2C0 3.10156 0.838623 4 1.875 4C2.91138 4 3.75 3.10156 3.75 2C3.75 0.894532 2.91138 0 1.875 0ZM7.5 0C6.46362 0 5.625 0.894532 5.625 2C5.625 3.10156 6.46362 4 7.5 4C8.53638 4 9.375 3.10156 9.375 2C9.375 0.894532 8.53638 0 7.5 0ZM13.125 0C12.0886 0 11.25 0.894532 11.25 2C11.25 3.10156 12.0886 4 13.125 4C14.1614 4 15 3.10156 15 2C15 0.894532 14.1614 0 13.125 0Z" fill="#0046E6"/>
                                        </svg>
                                    </div>
                                    {
                                        item._id===activeAction &&
                                        <OutsideClickHandler
                                            onOutsideClick={() => {
                                                setActiveAction(null)
                                            }}
                                        >
                                            <div className="absolute z-10 p-2 rounded bg-white shadow-md right-0 font-bold border-[1px] border-red-500">
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#DC6803] whitespace-nowrap" ,
                                                            item.status!=="active"&&"hidden"
                                                        )}
                                                        onClick={()=>updateMandateStatus("pause", item._id)} 
                                                    >Pause Mandate</p>
                                                </div>
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#027A48] whitespace-nowrap",
                                                            item.status!=="paused"&&"hidden" 
                                                        )}
                                                        onClick={()=>updateMandateStatus("resume", item._id)} 
                                                    >Resume Mandate</p>
                                                </div>
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#D92D20] whitespace-nowrap",
                                                            item.status==="deactivated"&&"hidden" 
                                                        )}
                                                        onClick={()=>updateMandateStatus("cancel", item._id)} 
                                                    >Cancel Mandate</p>
                                                </div>
                                            </div>
                                        </OutsideClickHandler>
                                    }
                                </div>
                                <TableDropdown item={item} expand={(val)=>setViewData(val)} />
                            </div>
                        ),
                    },
                ]}
            />
            <ShareMandateModal
                show={showModal}
                link={`${window.location.origin}/customer/direct-debit/${mandateData?._id}/${b2b_data.user.businessId}?appId=${appId}&account=${mandateData?.status==="active"||mandateData?.consents?.length>0?"backup":"primary"}`}
                handleClose={()=>setShowModal(false)}
            />
            <ModalLayout
                show={showStatusSuccess.show}
                handleClose={()=>{
                    setShowStatusSuccess({...showStatusSuccess, show: false})
                }}
            >
                <div className="flex flex-col items-center py-10">
                    <FaCheckCircle className="w-10 scale-[2.5] text-[#12B76A]" />
                    <p className="text-[32px] font-medium text-center mt-[20px] mb-1.5">Mandate {showStatusSuccess.type}{showStatusSuccess.type==="cancel"?"led":"d"} successfully</p>
                    <p className="text-[20px] text-center">You have {showStatusSuccess.type}{showStatusSuccess.type==="cancel"?"led":"d"} the mandate for this customer.</p>
                    <div className="w-full grid grid-cols-2 gap-3 mt-4">
                        <Button
                            isValid={true}
                            className="bg-white border-[1px] border-[#D0D5DD] !text-[#344054]"
                            text="Back"
                            onClick={()=>{
                                setShowStatusSuccess({...showStatusSuccess, show: false})
                            }}
                        />
                        <Button
                            isValid={true}
                            className="bg-cc-primary"
                            text={
                                showStatusSuccess.type==="pause" ? "Resume"
                                : showStatusSuccess.type==="resume" ? "Pause"
                                : "Continue"
                            }
                            onClick={()=>{
                                if(showStatusSuccess.type==="pause"){
                                    updateMandateStatus("resume", showStatusSuccess.id)
                                }  else if (showStatusSuccess.type==="resume") {
                                    updateMandateStatus("pause", showStatusSuccess.id)
                                } else {
                                    setShowStatusSuccess({...showStatusSuccess, show: false})
                                }
                            }}
                            loading={showStatusSuccess.loading}
                        />
                    </div>
                </div>
            </ModalLayout>
            <InitiateDebit
                show={showInitiatDebitModal}
                handleClose={()=>setShowInitiatDebitModal(false)}
                mandateId={mandateData?._id}
            />
        </div>
    )
}